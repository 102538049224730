<template>
  <div class="container">
    <div class="container_main"
         v-if='isGetForm'>
      <el-swiper :src='imgSrc'
                 :info='info'></el-swiper>
      <div class="container_ container1">
        <div class="main">
          <div class="part part1">
            <div class="title">尚盈期颐</div>
            <div class="bgimg"></div>
            <div class="dsc">尚盈车联 · 乐享小铺 · 尙盈名品</div>
            <div class="dsc_text">
              <p>我们有专业的技术团队，有高效的运营体系，有丰富的营销培训</p>
              <p>我们愿用自己的诚意，助您实现数字化时代的转型升级</p>
            </div>
            <ul class="pics">
              <li class="pic_li">
                <img class="pic_img"
                     src="../assets/images/business-cooperation_part1_1.png"
                     alt="">
                <p>集客锁客</p>
              </li>
              <li class="pic_li">
                <img class="pic_img"
                     src="../assets/images/business-cooperation_part1_2.png"
                     alt="">
                <p>高效管理</p>
              </li>
              <li class="pic_li">
                <img class="pic_img"
                     src="../assets/images/business-cooperation_part1_3.png"
                     alt="">
                <p>降本增润</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container_ container2">
        <div class="main">
          <div class="part part2">
            <div class="title">Cooperation mode</div>
            <div class="bgimg"></div>
            <div class="dsc">合作模式</div>
            <ul class="pics">
              <li class="pic_li">
                <img class="pic_img"
                     src="../assets/images/business-cooperation_part2_1.png"
                     alt="">
                <div class="info">
                  <div class="title">个体加盟</div>
                  <div class="dsc">定制开发·运营指导</div>
                  <div class="border_"></div>
                  <div class="explain">适合个体户、中小型商家</div>
                </div>
              </li>
              <li class="pic_li">
                <img class="pic_img"
                     src="../assets/images/business-cooperation_part2_2.png"
                     alt="">
                <div class="info">
                  <div class="title">平台租赁</div>
                  <div class="dsc">技术合作 · 运维支持</div>
                  <div class="border_"></div>
                  <div class="explain">适合政府、企事业单位</div>
                </div>
              </li>
              <li class="pic_li">
                <img class="pic_img"
                     src="../assets/images/business-cooperation_part2_3.png"
                     alt="">
                <div class="info">
                  <div class="title">全国合伙人</div>
                  <div class="dsc">渠道代理 · 合作抽成</div>
                  <div class="border_"></div>
                  <div class="explain">适合有一定运营能力的企业/团队</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container_ container3">
        <div class="main">
          <div class="part part3">
            <p>有意者请点击<span class="color_">【招商合作】</span>按钮，按照芝士提交信息</p>
            <p class="last_p">我们将在7个工作日内予以回复</p>
            <div class="btn_go"
                 @click="showGetForm">招商合作 >></div>
          </div>
        </div>
      </div>
      <div class="container_ container4">
        <div class="main">
          <div class="part part4">
            <div class="title">In the process</div>
            <div class="bgimg"></div>
            <div class="dsc">入驻流程</div>
            <ul class="pics">
              <li class="pic_li">
                <div class="bgimg bgimg_0">
                </div>
                <div class="text">
                  <div class="title">申请合作</div>
                  <div class="info1">企业资质</div>
                  <div class="info2">合作意向</div>
                </div>
              </li>
              <li class="pic_li">
                <div class="bgimg blue_arrow">
                </div>
              </li>
              <li class="pic_li">
                <div class="bgimg bgimg_1">
                </div>
                <div class="text">
                  <div class="title">资质审核</div>
                  <div class="info1">7个工作日内</div>
                  <div class="info2">短信或电话反馈</div>
                </div>
              </li>
              <li class="pic_li">
                <div class="bgimg blue_arrow">
                </div>
              </li>
              <li class="pic_li">
                <div class="bgimg bgimg_2">
                </div>
                <div class="text">
                  <div class="title">合作签约</div>
                  <div class="info1">数据/服务全打通</div>
                  <div class="info2">专业团队驻点实施</div>
                </div>
              </li>
              <li class="pic_li">
                <div class="bgimg blue_arrow">
                </div>
              </li>
              <li class="pic_li">
                <div class="bgimg bgimg_3">
                </div>
                <div class="text">
                  <div class="title">提供服务</div>
                  <div class="info1">系统执行方案</div>
                  <div class="info2">一站式服务</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="getForm"
         v-else>
      <div class="main">
        <div class="form">
          <div class="title">基础资料</div>
          <div class="form_">
            <el-form :model="formList"
                     ref="form"
                     :rules="rules"
                     label-width="150px"
                     class="textL">
              <el-form-item label="企业名称:"
                            prop="companyName">
                <el-input v-model="formList.companyName"></el-input>
              </el-form-item>
              <el-form-item label="合作意向 (可多选)："
                            prop="type">
                <el-checkbox-group v-model="formList.type">
                  <el-checkbox :label="1"
                               name="type">尚盈车联</el-checkbox>
                  <el-checkbox :label="2"
                               name="type">乐享小铺</el-checkbox>
                  <el-checkbox :label="3"
                               name="type">尚盈名品</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="合作模式："
                            prop="cooperative">
                <el-radio-group v-model="formList.cooperative">
                  <el-radio :label="1">个体加盟</el-radio>
                  <el-radio :label="2">平台租赁</el-radio>
                  <el-radio :label="3">全国合伙人</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="other_"
                            label="接收通知的联络人">
                <div class="text">（用于资质审核过程中接受尚盈车联的审核结果，请务必填写正确）</div>
              </el-form-item>
              <el-form-item label="联系人姓名:"
                            prop="userName">
                <el-input v-model="formList.userName"></el-input>
              </el-form-item>
              <el-form-item label="联系人电话:"
                            prop="userPhone">
                <el-input v-model="formList.userPhone"></el-input>
              </el-form-item>
              <el-button class="submitBtn"
                         type="primary"
                         @click="submit">提交</el-button>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isGetForm: true,
      imgSrc: require('@/assets/images/banner3.png'),
      info: {
        title: '招商合作',
        dsc: 'Attract Investment',
        number: ''
      },
      formList: {
        companyName: '',
        type: [],
        cooperative: 1,
        userName: '',
        userPhone: ''
      },
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个合作意向', trigger: 'change' }
        ],
        cooperative: [
          { required: true, message: '请至少选择一个合作模式', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: '姓名不支持特殊字符',
            trigger: 'blur'
          }
        ],
        userPhone: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/ || /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/,
            message: '电话号码不正确',
            trigger: 'blur'
          }
        ],
      }
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isGetForm = true
        } else {
          return
        }
      })
    },
    showGetForm () {
      this.isGetForm = false
      document.documentElement.scrollTop = 0
      document.title = '尚盈期颐-招商合作-信息填报'
    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/business-cooperation.scss";
</style>